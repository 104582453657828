

const images = [
  { background: "url('/images/1-Alternative-HQ4A9129_1920x1080.jpg'", position: 'center left' },
  { background: "url('/images/2-HQ4A8999_1920x1080.jpg'", position: 'center right' },
  { background: "url('/images/3-HQ4A8828_sm_1920x1080.jpg'", position: 'center left' },
  { background: "url('/images/4-HQ4A8920_1920x1080.jpg'", position: 'center right' },
  { background: "url('/images/5-HQ4A8801_1920x1080.jpg'", position: 'center left' },
  { background: "url('/images/HQ4A8747.jpg'", position: 'center left' },
];

let interval = null;

const setPicture = (index) => {
  document.body.style.background = images[index].background;
  document.body.style.backgroundPosition = images[index].position;
  document.body.style.backgroundSize = 'cover';
};

const createIntervalLoop = () => {
  interval = setInterval(() => {
    const index = Math.round(Math.abs(Math.random() * 5));
    setPicture(index);
  }, 6000);
};

document.addEventListener('keypress', (event) => {
  if (['1', '2', '3', '4', '5', '6'].includes(event.key)) {
    setPicture(parseInt(event.key, 10) - 1);
    clearInterval(interval);
  }
  if (event.key === '0') {
    createIntervalLoop();
  }
});


createIntervalLoop();
